let seeMoreBtns = Array.from(document.querySelectorAll(".cms-id-11 .see-more-btn"));
let timeslots = Array.from(document.getElementsByClassName("time-slot"));
if(seeMoreBtns && timeslots){
    const hiddenTimeslots = [];
    timeslots.forEach((timeslot, index) => {
        if(index > 4){
            hiddenTimeslots.push(timeslot);
        }
    });
    console.log(hiddenTimeslots);
    seeMoreBtns.forEach(btn => {
        btn.addEventListener("click", function(){
            hiddenTimeslots.forEach(hiddenTimeslot => {
                if(hiddenTimeslot.style.display === "flex") {
                    hiddenTimeslot.style.display = "none";
                    btn.innerText = "Voir plus";
                } else {
                    hiddenTimeslot.style.display = "flex";
                    btn.innerText = "Voir moins";
                }
            });
        });
    });
}