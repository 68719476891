// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

let homepage = document.getElementById("index");
if(homepage) {
  let homesliderContainer = document.getElementsByClassName("homeslider-container");
  let boPauseOnHover = homesliderContainer[0].getAttribute("data-pause");
  let boDelay = parseInt(homesliderContainer[0].getAttribute("data-interval"));

  if(boPauseOnHover === "hover") {
      boPauseOnHover = true;
  } else {
      boPauseOnHover = false;
  }

  // init Swiper:
  var swiper = new Swiper(".hp-swiper", {
    slidesPerView: 1,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function () {
            return `  <svg class="swiper-pagination-bullet" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.5" cy="12.5" r="12.5" fill="#BEBEBE"/>
                        </svg>`;
        },
    },
    autoplay: {
        delay: boDelay,
        disableOnInteraction: false,
        pauseOnMouseEnter: boPauseOnHover,
    },
    loop: true,
  });
}

let productPage = document.getElementById("product");
if(productPage){
  let productImages = document.querySelectorAll(".product-images-swiper .swiper-wrapper .swiper-slide img");
  let sources = [];
  for(let i=0; i< productImages.length; i++){
    if(!sources.includes(productImages[i].attributes.src.value)){
      sources.push(productImages[i].attributes.src.value);
    }
  }
  var productImagesSwiper = new Swiper(".product-images-swiper", {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      992: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index) {
            return `<div class="swiper-pagination-bullet col-lg-3 px-lg-2"><img src=${sources[index]} /></div>`;
          }
        },
      },
    },
  });
}

// init Swiper:
var productSwiper = new Swiper(".products-swiper", {
  slidesPerView: "auto",
  centeredSlides: false,
  loop: false,
  breakpoints: {
    992: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
  }
});

// init Swiper:
var universesSwiper = new Swiper(".universes-swiper", {
  slidesPerView: "auto",
  centeredSlides: false,
  loop: false,
  breakpoints: {
    992: {
      slidesPerView: 6,
      allowSlideNext: false,
      allowSlidePrev: false,
      noSwiping: true,
    },
  }
});

var featuredProductsSwiper = new Swiper(".featured-products-swiper", {
  slidesPerView: 2.5,
  centeredSlides: false,
  loop: false,
  breakpoints: {
    992: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
  }
});

var prestablogHpSwiper = new Swiper(".prestablog-hp-swiper", {
  slidesPerView: 3,
  slidesPerGroup: 3,
  centeredSlides: false,
  loop: false,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

var reassurancesSwiper = new Swiper(".reassurances-swiper", {
  slidesPerView: 2,
  slidesPerGroup: 1,
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  breakpoints: {
    768: {
      slidesPerView: 3,
    },
    992: {
      slidesPerView: 4,
    },
    1200: {
      slidesPerView: 6,
      autoplay: false,
      allowSlideNext: false,
      allowSlidePrev: false,
    },
  },
  /* navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  }, */
});