let identityPage = document.querySelector("body.page-identity");
let addressPage = document.querySelector("body.page-address");

//If user is on 'My informations' page or 'Add a new address' page : 
if(identityPage || addressPage){
    let iAmProfessionalBtn = document.querySelector("form .form-group #professional");
    if(iAmProfessionalBtn) {
        let professionalFormFields = document.querySelector("form .professional-form-groups");
        if(professionalFormFields){
            //If user selects 'I am a professional' button, the professional fields are displayed
            iAmProfessionalBtn.addEventListener("click", function(){
                if(iAmProfessionalBtn.checked){
                    professionalFormFields.style.display = "block";
                }else {
                    professionalFormFields.style.display = "none";
                }
            })
        }
    }
}