import $ from 'jquery';
export default class ToggleOrderDetails
{
    init() {
        const self = this;
        self.toggleOrderDetails();
    }

    toggleOrderDetails() {
        $('.ol-toggle-details svg').on('click', function() {
            $(this).toggleClass('open');
            $(this).parents('.order-summary').siblings('.od-bloc').find('.order-details').slideToggle();
        })
    }
}